<template>
  <div class="title-editor">
    <div
        class="title-editor__input"
        ref="editorInput"
        contenteditable="true"
        v-on:blur="editTitle($event)"
        v-on:keyup.enter.prevent="$event.target.blur()"
    >
      {{value}}
    </div>
    <button
      class="title-editor__button"
      v-on:click="setFocus"
    >
      <Icon name="pen" css-class="title-editor__icon" />
    </button>
  </div>
</template>

<script>
import Icon from "@/components/generic/Icon";

export default {
  name: 'title-editor',
  components: {Icon},
  props: {
    value: {
      type: String,
    },
  },
  methods: {
    editTitle(event) {
      if (event.target.innerText !== this.value) {
        this.$emit('editTitle', event.target.innerText);
      }
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs.editorInput.focus();
        document.execCommand('selectAll', false, null);
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.title-editor {
  width: 100%;
  display: flex;
  font-size: inherit;
  color: inherit;
  white-space:nowrap;

  &__input {
    border:none;
    background-color: transparent;
    outline: none;
    font-size: inherit;
    color: inherit;
    margin-right: rem(10px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
  }

  &__button {
    border:none;
    vertical-align: middle;
    background-color: transparent;
    margin-left: rem(6px);
    cursor: pointer;
    color: inherit;
    font-size: inherit;

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  &__icon {
    color: inherit;
    font-size: inherit;
  }
}
</style>
